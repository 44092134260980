import VerticalMenu_VerticalMenuTextSkinComponent from '../components/VerticalMenu/viewer/skinComps/VerticalMenuTextSkin/VerticalMenuTextSkin.skin';
import VerticalMenu_VerticalMenuTextSkinController from '../components/VerticalMenu/viewer/VerticalMenu.controller';


const VerticalMenu_VerticalMenuTextSkin = {
  component: VerticalMenu_VerticalMenuTextSkinComponent,
  controller: VerticalMenu_VerticalMenuTextSkinController
};


export const components = {
  ['VerticalMenu_VerticalMenuTextSkin']: VerticalMenu_VerticalMenuTextSkin
};

